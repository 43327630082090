import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import AboutUsPage from '../pagesComponents/aboutUsPage';

const AboutUs = ({ location }) => (
  <Layout location={location}>
    <AboutUsPage />
  </Layout>
);

AboutUs.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AboutUs;
